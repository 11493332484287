import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'

const builderApiToken = process.env.NEXT_PUBLIC_BUILDER_API_KEY

const builderLink = createHttpLink({
  uri: `https://cdn.builder.io/api/v1/graphql/${builderApiToken}`,
})

const builderClient = new ApolloClient({
  link: builderLink,
  cache: new InMemoryCache(),
})

export default builderClient
